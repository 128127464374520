<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div class="tbl_opt">
          <span class="option_txt">조회 기간</span>
          <div>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="start_date"
                @change="setStartDate($event)"
                v-if="showSearchDate"
              />
            </div>
            <p>~</p>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                @change="setEndDate($event)"
                :value="end_date"
                v-if="showSearchDate"
              />
            </div>
          </div>
          <!-- v-show="filteredExpectedYN.length > 0" -->
          <div class="input_checkbox">
            <label
              for="checkbox1"
              class="chk_box"
              :class="toggleCheckTotalCost ? 'active' : '_'"
            >
              <i class="fa fa-check"></i>
            </label>
            <input type="checkbox" id="checkbox1" @click="toggleCheckBox()" />
            <label for="checkbox1" class="label_txt">{{
              '매출 예정액 포함'
            }}</label>
          </div>
          <button
            class="excel_btn"
            v-show="filteredExpectedYN.length > 0"
            @click="exportExcel"
          >
            엑셀로 출력
          </button>
        </div>
      </div>
      <div class="ranking">
        <ul>
          <li
            v-for="(item, index) in rankList.length > 0 ? rankList : 3"
            :key="index"
          >
            <h6
              class="rank_product"
              :class="{ no_hover: item.product_id == undefined }"
            >
              {{ findInfoFromId(companys, item.company_id).name }}
              <span>&#8361; {{ $makeComma(item.total_cost) }}</span>
            </h6>
          </li>
        </ul>
      </div>
      <h6 class="views">조회수: {{ filteredExpectedYN.length }}건</h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th>매출순위</th>
              <th>거래처명</th>
              <th>총 매출액</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in filteredExpectedYN" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ findInfoFromId(companys, item.company_id).name }}</td>
              <td>&#8361; {{ $makeComma(item.total_cost) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FavoriteMixin from '@/mixins/favorite';
import FETCH_MIXIN from '@/mixins/fetch';
import { yyyymmdd, yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';
import XLSX from 'xlsx';
export default {
  mixins: [FavoriteMixin, FETCH_MIXIN],

  data() {
    return {
      showSearchDate: false,
      toggleCheckTotalCost: false,
    };
  },
  computed: {
    ...mapGetters({
      sales_src: 'getSales',
      companys: 'getCompany',
      start_date: 'getCompanyStartDateFromSales',
      end_date: 'getCompanyEndDateFromSales',
      collections: 'getCollection',
      // toggleCheckTotalCost: 'getCheckOnlyNotCompletedFromSales',
    }),
    filteredExpectedYN() {
      if (this.sales_src == undefined || this.collections == undefined) {
        return [];
      } else {
        let companySum = this.lodash
          .clonedeep(this.sales_src)
          .map(x =>
            x.product_list
              .map(y => ({
                ...y,
                company_id: x.company_id,
              }))
              .filter(
                x => x.output_yn && x.collect_value == 0 && x.total_cost > 0,
              ),
          )
          .reduce((a, b) => a.concat(b), []);
        console.log(companySum, 'companySum');
        let collectionSum = this.lodash
          .clonedeep(this.collections)
          .filter(x => x.sales_product_id != null);

        let companyCollection = [];
        if (this.toggleCheckTotalCost) {
          companyCollection = companySum.concat(collectionSum);
        } else {
          companyCollection = collectionSum;
        }
        console.log(companyCollection, 'companyCollection');
        let obj = companyCollection.reduce(function(rv, x) {
          (rv[x['company_id']] = rv[x['company_id']] || []).push(x);
          return rv;
        }, {});
        let arr = [];
        for (let k in obj) {
          arr.push({
            company_id: k,
            total_cost: obj[k]
              .map(x => x.total_value || x.total_cost)
              .reduce((a, b) => a + b, 0),
          });
        }
        return arr.sort((a, b) => b.total_cost - a.total_cost);
      }
    },
    rankList() {
      if (this.filteredExpectedYN.length > 0) {
        const filterList = this.filteredExpectedYN.filter(
          (x, index) => index < 3,
        );
        let arr = [
          filterList.length < 2 ? {} : filterList[1],
          filterList.length < 1 ? {} : filterList[0],
          filterList.length < 3 ? {} : filterList[2],
        ];
        return arr;
      } else {
        return [];
      }
    },
  },
  methods: {
    async setStartDate(e) {
      if (e.target.value != '') {
        // this.$store.commit('setCompanyStartDateToSales', e.target.value);
        if (e.target.value > this.end_date) {
          this.openOneButtonModal(
            '납품요청일 오류',
            `납품요청일 최후 날짜(${this.end_date}) 이후의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.start_date;
        } else {
          this.$store.commit('setCompanyStartDateToSales', e.target.value);
          await this.FETCH_SALES();
          await this.FETCH_COLLECTION();
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setCompanyStartDateToSales', yyyymmdd(new Date()));
      }
    },
    async setEndDate(e) {
      if (e.target.value != '') {
        // this.$store.commit('setCompanyEndDateToSales', e.target.value);
        if (e.target.value < this.start_date) {
          this.openOneButtonModal(
            '납품요청일 오류',
            `납품요청일 최초 날짜(${this.start_date}) 이전의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.end_date;
        } else {
          this.$store.commit('setCompanyEndDateToSales', e.target.value);
          await this.FETCH_SALES();
          await this.FETCH_COLLECTION();
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setCompanyEndDateToSales', yyyymmdd(new Date()));
      }
    },
    async toggleCheckBox() {
      this.toggleCheckTotalCost = !this.toggleCheckTotalCost;
      await this.FETCH_SALES();
    },
    async FETCH_COLLECTION() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_COLLECTION', {
          start: this.start_date,
          end: this.end_date,
        })
        .then(() => {})
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            '수금 리스트를 불러오는 중 오류발생',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    async FETCH_SALES() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_SALES', {
          start_date: this.start_date,
          end_date: this.end_date,
          toggleCheckTotalCost: false,
        })
        .then(() => {})
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            '수주 리스트를 불러오는 중 오류발생',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    exportExcel() {
      let rankList = [];
      let date = this.start_date + '~' + this.end_date;

      const merge = [
        { s: { r: 0, c: 1 }, e: { r: 0, c: 4 } },
        { s: { r: 1, c: 1 }, e: { r: 1, c: 4 } },
      ];
      let standardInfo = [
        ['기준시간', yyyy년MM월dd일HH시mm분ss초(new Date())],
        ['조회기간', date],
        [],
        [],
        [],
      ];
      rankList = rankList.concat(standardInfo);

      rankList.push(['매출순위', '거래처명', '총 매출액']);

      this.filteredExpectedYN.forEach((el, index) => {
        rankList.push([
          index + 1,
          this.findInfoFromId(this.companys, el.company_id).name,
          this.$makeComma(el.total_cost),
        ]);
      });

      let wb = XLSX.utils.book_new();
      let complete = XLSX.utils.json_to_sheet(rankList, {
        skipHeader: true,
      });

      complete['!merges'] = merge;

      XLSX.utils.book_append_sheet(wb, complete, '거래처별 실적현황');

      let title = '거래처별 실적현황(' + date + ').xls';
      XLSX.writeFile(wb, title);
    },
  },
  async created() {
    if (this.start_date == null) {
      var date = new Date();
      this.$store.commit('setCompanyEndDateToSales', yyyymmdd(date));
      var date2 = new Date(date.setMonth(date.getMonth() - 1));
      this.$store.commit('setCompanyStartDateToSales', yyyymmdd(date2));
      await this.FETCH_SALES();
    }
    this.showSearchDate = true;

    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.sales_src.length < 1) {
      await this.FETCH_SALES();
    }
    await this.FETCH_COLLECTION();
  },
};
</script>

<style></style>
