<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="option_cont tbl_option">
        <div class="opt_top">
          <div class="option_date">
            <span>판매일자 조회</span>
            <div>
              <div class="input_text">
                <input
                  type="date"
                  placeholder="YYYY-MM-DD"
                  id="start_date"
                  :value="start_date"
                  @change="setStartDate($event)"
                />
              </div>
              <p>~</p>
              <div class="input_text">
                <input
                  type="date"
                  placeholder="YYYY-MM-DD"
                  id="end_date"
                  @change="setEndDate($event)"
                  :value="end_date"
                />
              </div>
            </div>
          </div>
          <div class="input_text">
            <span>매출액</span
            ><input
              type="text"
              class="text_right"
              readonly
              :value="
                !isNaN(totalPrice.total_value)
                  ? `₩ ${$makeComma(totalPrice.total_value)}`
                  : `₩ 0`
              "
            />
          </div>
          <div class="input_text">
            <span>실수익</span
            ><input
              type="text"
              class="text_right"
              readonly
              :value="
                !isNaN(totalPrice.total_value) || !isNaN(totalPrice.real_profit)
                  ? `₩ ${$makeComma(
                      $decimalSub(
                        Number(totalPrice.total_value),
                        Number(totalPrice.real_profit),
                      ),
                    )}`
                  : `₩ 0`
              "
            />
          </div>
          <button class="excel_btn">엑셀로 저장</button>
        </div>
        <div class="opt_bottom">
          <div v-for="(n, index_1) in 2" :key="index_1">
            <h6>실적상위 {{ index_1 == 0 ? `업체` : `제품` }}</h6>
            <ul>
              <li v-for="(n, index_2) in 3" :key="index_2">
                <span>{{ index_2 + 1 }}위</span>
                <p>
                  {{
                    index_1 == 0
                      ? filteredCustomer[index_2] != undefined ? findInfoFromId(companys,filteredCustomer[index_2].company_id).name : '' 
                      : filteredProduct[index_2] != undefined ? findInfoFromId(products,filteredProduct[index_2].product_id).name : '',
                  }}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <h6 class="views">조회수: {{ filteredAll.length }}건</h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th>판매일자</th>
              <th>판매제품</th>
              <th>거래처명</th>
              <th>수량(단위)</th>
              <th>총 매출액</th>
              <th>총 소요액</th>
              <th>총 실적액</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in filteredAll" :key="item.id">
              <td>{{ item.create_time | formatDateNoHours }}</td>
              <td>{{ findInfoFromId(products, item.product_id).name }}</td>
              <td>{{ findInfoFromId(companys, item.company_id).name }}</td>
              <td>
                {{ item.quantity }}
                {{
                  item.quantity != undefined
                    ? `(${findInfoFromId(units, item.unit_id).name})`
                    : ''
                }}
              </td>
              <td>&#8361; {{ $makeComma(item.total_value) }}</td>
              <td>
                &#8361;
                {{
                  refineAllResource.find(x => item.lot_id == x.lot_id) !=
                  undefined
                    ? $makeComma(
                        refineAllResource.find(x => item.lot_id == x.lot_id)
                          .require_amount,
                      )
                    : 0
                }}
              </td>
              <td>
                &#8361;
                {{
                  refineAllResource.find(x => item.lot_id == x.lot_id) !=
                  undefined
                    ? $makeComma(
                        $decimalSub(
                          item.total_value,
                          refineAllResource.find(x => item.lot_id == x.lot_id)
                            .require_amount,
                        ),
                      )
                    : $makeComma(item.total_value)
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { yyyymmdd } from '@/utils/func';
import FETCH_MIXIN from '@/mixins/fetch';

export default {
  mixins: [FETCH_MIXIN],
  computed: {
    ...mapGetters({
      newSalesSrc: 'getNewSalesFromOutput',
      collections: 'getCollection',
      all_resource: 'getCostCalculationResourceAll',
      lots: 'getLot',
      unitConversions: 'getUnitConversion',
      start_date: 'getAllNewSalesStartDateFromOutput',
      end_date: 'getAllNewSalesEndDateFromOutput',
      sales_type: 'getSalesType',
      products: 'getProduct',
      companys: 'getCompany',
      materials: 'getMaterial',
      units: 'getUnitCodes',
    }),
    filteredCustomer() {
      if (this.collections == undefined) {
        return [];
      } else {
        let cloneFilterCollect = this.lodash
          .clonedeep(this.collections)
          .filter(
            x => x.sales_product_id != null && x.sales_account_id == null,
          );
        let obj = cloneFilterCollect.reduce(function(rv, x) {
          (rv[x['company_id']] = rv[x['company_id']] || []).push(x);
          return rv;
        }, {});
        let arr = [];
        for (let k in obj) {
          arr.push({
            company_id: k,
            total_cost: obj[k]
              .map(x => x.total_value || x.total_cost)
              .reduce((a, b) => a + b, 0),
          });
        }
        return arr.sort((a, b) => b.total_cost - a.total_cost);
      }
    },
    filteredProduct() {
      if (this.collections == undefined || this.newSalesSrc == undefined) {
        return [];
      } else {
        const cloneSales = this.lodash
          .clonedeep(this.newSalesSrc)
          .filter(
            x =>
              !this.findInfoFromId(
                this.sales_type,
                x.sales.sales_type_id,
              ).detail.includes('return'),
          )
          .map(x => x.sales_product_list)
          .reduce((a, b) => a.concat(b), []);
        const cloneFilterCollect = this.lodash
          .clonedeep(this.collections)
          .filter(x => x.sales_product_id != null && x.sales_account_id == null)
          .map(x => ({
            ...x,
            product_id: this.findInfoFromId(cloneSales, x.sales_product_id)
              .product_id,
          }));
        const obj = cloneFilterCollect.reduce(function(rv, x) {
          (rv[x['product_id']] = rv[x['product_id']] || []).push(x);
          return rv;
        }, {});

        let arr = [];
        for (let k in obj) {
          arr.push({
            product_id: k,
            total_cost: obj[k]
              .map(x => x.total_cost || x.total_value)
              .reduce((a, b) => a + b, 0),
          });
        }
        return arr.sort((a, b) => b.total_cost - a.total_cost);
      }
    },
    filteredAll() {
      if (this.collections.length < 1 || this.newSalesSrc.length < 1) {
        return [];
      } else {
        const cloneSales = this.lodash
          .clonedeep(this.newSalesSrc)
          .filter(
            x =>
              !this.findInfoFromId(
                this.sales_type,
                x.sales.sales_type_id,
              ).detail.includes('return'),
          )
          .map(x => x.sales_product_list)
          .reduce((a, b) => a.concat(b), []);
        const cloneFilterCollect = this.lodash
          .clonedeep(this.collections)
          .filter(x => x.sales_product_id != null && x.sales_account_id == null)
          .map(x => ({
            ...x,
            unit_id: this.findInfoFromId(cloneSales, x.sales_product_id)
              .unit_id,
            quantity: this.findInfoFromId(cloneSales, x.sales_product_id)
              .quantity,
            product_id: this.findInfoFromId(cloneSales, x.sales_product_id)
              .product_id,
            lot_id: this.findInfoFromId(cloneSales, x.sales_product_id)
              .product_in_out_list[0].product_control.lot_id,
          }))
          .sort((a, b) => new Date(b.create_time) - new Date(a.create_time));

        return cloneFilterCollect;
      }
    },
    refineAllResource() {
      if (
        this.all_resource.length < 1 ||
        this.filteredAll.length < 1 ||
        this.lots.length < 1
      ) {
        return [];
      } else {
        const listTemp = this.lodash
          .clonedeep(this.all_resource)
          .filter(x => this.filteredAll.find(y => y.lot_id == x.lot_id));
        const obj = listTemp.reduce(function(rv, x) {
          (rv[x['lot_id']] = rv[x['lot_id']] || []).push(x);
          return rv;
        }, {});
        let arr = [];
        for (let k in obj) {
          obj[k].map(x => {
            let cvr_ratio = null;
            const target_mat = this.findInfoFromId(
              this.materials,
              x.material_id,
            );
            const foundItem = this.unitConversions.find(
              x =>
                x.in_unit_id == target_mat.incoming_unit_id &&
                x.out_unit_id == target_mat.using_unit_id,
            );
            if (foundItem != null) {
              cvr_ratio = foundItem.cvr_ratio;
            } else {
              cvr_ratio = 1;
            }
            x.cal_cost = this.$decimalMul(x.cost, cvr_ratio);
            return x;
          });
          arr.push({
            lot_id: k,
            require_amount: obj[k]
              .map(x =>
                this.$decimalMul(
                  this.$decimalDiv(
                    this.$decimalMul(x.cal_cost, x.quantity),
                    this.findInfoFromId(this.lots, k).quantity,
                  ),
                  this.filteredAll.find(x => x.lot_id == k).quantity,
                ),
              )
              .reduce((a, b) => this.$decimalAdd(a, b), 0),
          });
        }
        return arr;
      }
    },
    totalPrice() {
      if (this.filteredAll.length > 0) {
        let total = {
          total_value: this.filteredAll
            .map(x => x.total_value)
            .reduce((a, b) => this.$decimalAdd(a, b)),

          real_profit: this.refineAllResource
            .map(x => x.require_amount)
            .reduce((a, b) => this.$decimalAdd(a, b)),
        };
        return total;
      } else {
        return [];
      }
    },
  },
  methods: {
    getUnitConversion(material_id) {
      const target_mat = this.findInfoFromId(this.materials, material_id);
      const foundItem = this.unitConversions.find(
        x =>
          x.in_unit_id == target_mat.incoming_unit_id &&
          x.out_unit_id == target_mat.using_unit_id,
      );
      if (foundItem != null) {
        return foundItem.cvr_ratio;
      } else {
        return 1;
      }
    },
    setStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.end_date) {
          this.openOneButtonModal(
            '출고일 조회기간 오류',
            `출고일 조회기간 최후날짜(${this.end_date})<br>이후의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.start_date;
        } else {
          this.$store.commit('setAllNewSalesStartDateToOutput', e.target.value);
          this.FETCH_COLLECTION();
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit(
          'setAllNewSalesStartDateToOutput',
          yyyymmdd(new Date()),
        );
      }
    },
    setEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.start_date) {
          this.openOneButtonModal(
            '출고일 조회기간 오류',
            `출고일 조회기간 최초날짜(${this.start_date})<br>이전의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.end_date;
        } else {
          this.$store.commit('setAllNewSalesEndDateToOutput', e.target.value);
          this.FETCH_WITH_PAYLOAD(
            'FETCH_SALES_ACCOUNT_DAY_ALL_TO_OUTPUT',
            {
              start: `1950-01-01`,
              end: this.end_date,
            },
            '매출 내역',
          );
          this.FETCH_COLLECTION();
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit(
          'setAllNewSalesEndDateToOutput',
          yyyymmdd(new Date()),
        );
      }
    },
    async FETCH_COLLECTION() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_COLLECTION', {
          start: this.start_date,
          end: this.end_date,
        })
        .then(() => {})
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            '수금 리스트를 불러오는 중 오류발생',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
  },
  async created() {
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.sales_type.length < 1) {
      await this.FETCH('FETCH_SALES_TYPE', '매출유형');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    }
    if (this.unitConversions.length < 1) {
      await this.FETCH('FETCH_UNIT_CONVERSION', '단위환산');
    }
    if (this.all_resource.length < 1) {
      await this.FETCH(
        'FETCH_COST_CALCULATION_RESOURCE_WITH_COST_ALL',
        '원자재 원가산출',
      );
    }
    if (this.lots.length < 1) {
      await this.FETCH('FETCH_LOT_ALL', '작업지시서');
    }
    if (this.start_date == null) {
      var date = new Date();
      await this.$store.commit('setAllNewSalesEndDateToOutput', yyyymmdd(date));
      var date2 = new Date(date.setMonth(date.getMonth() - 1));
      await this.$store.commit(
        'setAllNewSalesStartDateToOutput',
        yyyymmdd(date2),
      );
    }
    await this.FETCH_WITH_PAYLOAD(
      'FETCH_SALES_ACCOUNT_DAY_ALL_TO_OUTPUT',
      {
        start: `1950-01-01`,
        end: this.end_date,
      },
      '매출 내역',
    );
    await this.FETCH_COLLECTION();
  },
};
</script>

<style></style>
