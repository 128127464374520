<template>
  <div
    id="contents"
    :class="{
      product_performance_status: tabIndex == 0 || tabIndex == 1,
      performance_profit_status: tabIndex == 2,
    }"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToSalesAnalysisPage'"
          :options="asideLists"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li
            v-for="(asideList, index) in asideLists"
            :key="index"
            :class="{ active: tabIndex == index }"
          >
            <a href="#" @click.prevent="SetOpenTabIndex(index)">{{
              asideList.title
            }}</a>
          </li>
        </ul>
      </div>
      <CustomerPerformanceForm v-if="tabIndex == 0" />
      <ProductPerformanceStatusForm v-if="tabIndex == 1" />
      <PerformanceProfitForm v-if="tabIndex == 2" />
    </div>
  </div>
</template>

<script>
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import { mapGetters, mapMutations } from 'vuex';
import CustomerPerformanceForm from '@/views/forms/Business/CustomerPerformanceForm';
import ProductPerformanceStatusForm from '@/views/forms/Business/ProductPerformanceStatusForm';
import PerformanceProfitForm from '@/views/forms/Business/PerformanceProfitForm';
export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    CustomerPerformanceForm,
    ProductPerformanceStatusForm,
    PerformanceProfitForm,
    AsideSelectric,
  },
  data() {
    return {
      asideLists: [
        { title: '거래처별 실적현황', name: 'customer_performance_status' },
        { title: '제품별 실적현황', name: 'product_performance_status' },
        { title: '종합실적/이익현황', name: 'performance_profit_status' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromSalesAnalysisPage',
    }),
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToSalesAnalysisPage',
    }),
  },
  // created() {
  //   if (this.$route.meta.menu_init == true) {
  //     this.$store.commit('InitSalesAnalysisPageStore');
  //     this.$route.meta.menu_init = false;
  //   }
  // },
};
</script>

<style scoped></style>
