<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div class="tbl_opt">
          <span class="option_txt">조회 기간</span>
          <div>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                id="start_date"
                :value="start_date"
                @change="setStartDate($event)"
                v-if="showSearchDate"
              />
            </div>
            <p>~</p>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                id="end_date"
                @change="setEndDate($event)"
                :value="end_date"
                v-if="showSearchDate"
              />
            </div>
          </div>
          <div class="input_checkbox">
            <label
              for="checkbox1"
              class="chk_box"
              :class="salesToggle ? 'active' : '_'"
            >
              <i class="fa fa-check"></i>
            </label>
            <input type="checkbox" id="checkbox1" @click="toggleCheckBox()" />
            <label for="checkbox1" class="label_txt">매출 예정액 포함</label>
          </div>
          <button
            class="excel_btn"
            v-show="filteredList.length > 0"
            @click="exportExcel"
          >
            엑셀로 출력
          </button>
        </div>
      </div>
      <div class="ranking">
        <ul>
          <li
            v-for="(item, index) in rankList.length > 0 ? rankList : 3"
            :key="index"
          >
            <h6
              class="rank_product"
              :class="{ no_hover: item.product_id == undefined }"
            >
              {{ getProductInfo(item).name }}
              <span>&#8361; {{ getTotalCost(item) | makeComma }}</span>
            </h6>
          </li>
        </ul>
      </div>
      <h6 class="views">
        조회수:
        {{ filteredList.length }}건
      </h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th>매출순위</th>
              <th>제품명</th>
              <th>총 매출액</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in filteredList" :key="item.id">
              <td>{{ index + 1 }}</td>
              <td>{{ getProductInfo(item).name }}</td>
              <td>&#8361; {{ getTotalCost(item) | makeComma }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { yyyymmdd, yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import XLSX from 'xlsx';
export default {
  mixins: [FETCH_MIXIN],
  data() {
    return {
      showSearchDate: false,
      salesToggle: false,
    };
  },
  computed: {
    ...mapGetters({
      start_date: 'getNewSalesStartDate2FromOutput',
      end_date: 'getNewSalesEndDate2FromOutput',
      sales_type: 'getSalesType',
      newSalesSrc: 'getNewSalesFromOutput',
      collections: 'getCollection',
      products: 'getProduct',
    }),
    filteredList() {
      if (this.collections == undefined || this.newSalesSrc == undefined) {
        return [];
      } else {
        const cloneSales = this.lodash
          .clonedeep(this.newSalesSrc)
          .filter(
            x =>
              !this.findInfoFromId(
                this.sales_type,
                x.sales.sales_type_id,
              ).detail.includes('return'),
          )
          .map(x => x.sales_product_list)
          .reduce((a, b) => a.concat(b), []);
        const cloneFilterSales = cloneSales.filter(
          x => x.output_yn && x.collect_value == 0 && x.total_cost > 0,
        );
        const cloneFilterCollect = this.lodash
          .clonedeep(this.collections)
          .filter(x => x.sales_product_id != null && x.sales_account_id == null)
          .map(x => ({
            ...x,
            product_id: this.findInfoFromId(cloneSales, x.sales_product_id)
              .product_id,
          }));
        let cloneFilterList = [];
        if (this.salesToggle) {
          cloneFilterList = cloneFilterCollect.concat(cloneFilterSales);
        } else {
          cloneFilterList = cloneFilterCollect;
        }
        const obj = cloneFilterList.reduce(function(rv, x) {
          (rv[x['product_id']] = rv[x['product_id']] || []).push(x);
          return rv;
        }, {});

        let arr = [];
        for (let k in obj) {
          arr.push({
            product_id: k,
            total_cost: obj[k]
              .map(x => x.total_cost || x.total_value)
              .reduce((a, b) => a + b, 0),
          });
        }
        return arr.sort((a, b) => b.total_cost - a.total_cost);
      }
    },
    rankList() {
      if (this.filteredList != undefined || this.filteredList.length > 0) {
        let filterSales = this.filteredList.filter((x, index) => index < 3);
        let arr = [];
        arr = [
          filterSales.length < 2 ? {} : filterSales[1],
          filterSales.length < 1 ? {} : filterSales[0],
          filterSales.length < 3 ? {} : filterSales[2],
        ];
        return arr;
      } else {
        return [];
      }
    },
  },
  methods: {
    setStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.end_date) {
          this.openOneButtonModal(
            '출고일 조회기간 오류',
            `출고일 조회기간 최후날짜(${this.end_date})<br>이후의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.start_date;
        } else {
          this.$store.commit('setNewSalesStartDate2ToOutput', e.target.value);
          this.FETCH_WITH_PAYLOAD(
            'FETCH_SALES_ACCOUNT_DAY_ALL_TO_OUTPUT',
            {
              start: this.start_date,
              end: this.end_date,
            },
            '매출 내역',
          );
          this.FETCH_COLLECTION();
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit(
          'setNewSalesStartDate2ToOutput',
          yyyymmdd(new Date()),
        );
      }
    },
    setEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.start_date) {
          this.openOneButtonModal(
            '출고일 조회기간 오류',
            `출고일 조회기간 최초날짜(${this.start_date})<br>이전의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.end_date;
        } else {
          this.$store.commit('setNewSalesEndDate2ToOutput', e.target.value);
          this.FETCH_WITH_PAYLOAD(
            'FETCH_SALES_ACCOUNT_DAY_ALL_TO_OUTPUT',
            {
              start: this.start_date,
              end: this.end_date,
            },
            '매출 내역',
          );
          this.FETCH_COLLECTION();
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setNewSalesEndDate2ToOutput', yyyymmdd(new Date()));
      }
    },
    getProductInfo(item) {
      if (item != undefined) {
        let hit = this.products.find(x => x.id == item.product_id);
        return hit != undefined ? hit : '';
      } else {
        return '';
      }
    },
    getTotalCost(item) {
      if (item != undefined) {
        return item.total_cost;
      } else {
        return '';
      }
    },
    exportExcel() {
      let rankList = [];
      let date = this.start_date + '~' + this.end_date;

      const merge = [
        { s: { r: 0, c: 1 }, e: { r: 0, c: 4 } },
        { s: { r: 1, c: 1 }, e: { r: 1, c: 4 } },
      ];
      let standardInfo = [
        ['기준시간', yyyy년MM월dd일HH시mm분ss초(new Date())],
        ['조회기간', date],
        [],
        [],
        [],
      ];
      rankList = rankList.concat(standardInfo);

      rankList.push(['매출순위', '제품명', '총 매출액']);

      this.filteredList.forEach((el, index) => {
        rankList.push([
          index + 1,
          this.getProductInfo(el).name,
          this.makeComma(this.getTotalCost(el)),
        ]);
      });

      let wb = XLSX.utils.book_new();
      let complete = XLSX.utils.json_to_sheet(rankList, {
        skipHeader: true,
      });

      complete['!merges'] = merge;

      XLSX.utils.book_append_sheet(wb, complete, '제품별 실적현황');

      let title = '제품별 실적현황(' + date + ').xls';
      XLSX.writeFile(wb, title);
    },
    async FETCH_COLLECTION() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_COLLECTION', {
          start: this.start_date,
          end: this.end_date,
        })
        .then(() => {})
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            '수금 리스트를 불러오는 중 오류발생',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    async toggleCheckBox() {
      this.salesToggle = !this.salesToggle;
      await this.FETCH_WITH_PAYLOAD(
        'FETCH_SALES_ACCOUNT_DAY_ALL_TO_OUTPUT',
        {
          start: this.start_date,
          end: this.end_date,
        },
        '매출 내역',
      );
      await this.FETCH_COLLECTION();
    },
  },
  async created() {
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.sales_type.length < 1) {
      await this.FETCH('FETCH_SALES_TYPE', '매출유형');
    }
    if (this.start_date == null) {
      var date = new Date();
      await this.$store.commit('setNewSalesEndDate2ToOutput', yyyymmdd(date));
      var date2 = new Date(date.setMonth(date.getMonth() - 1));
      await this.$store.commit(
        'setNewSalesStartDate2ToOutput',
        yyyymmdd(date2),
      );
    }
    await this.FETCH_WITH_PAYLOAD(
      'FETCH_SALES_ACCOUNT_DAY_ALL_TO_OUTPUT',
      {
        start: this.start_date,
        end: this.end_date,
      },
      '매출 내역',
    );
    await this.FETCH_COLLECTION();
    this.showSearchDate = true;
  },
};
</script>

<style></style>
